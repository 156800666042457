<template>
  <div>
    <template v-if="section">
      <h3 class="text-2xl my-2">{{ lang.section.section }} {{ section.letter_code }}</h3>
      <div class="flex flex-wrap gap-4 mb-4">
        <a-badge :style="{ background: section.specialization.color, color: section.specialization.text_color }">
          {{ section.specialization.title }}
        </a-badge>
        <a-badge class="bg-pink-400 text-white text-opacity-90">
          Hembras: {{ section.females_assigned }} / {{ section.females_limit }}
        </a-badge>
        <a-badge class="bg-blue-400 text-white text-opacity-90">
          Varones: {{ section.males_assigned }} / {{ section.males_limit }}
        </a-badge>
      </div>
    </template>

    <a-table bordered :columns="user.isAdmin ? columns : columnsCoord" :source="(section || {}).courses || []" overflow :loading="loading">
      <template #td(teacher)="{ item, index }">
        <template v-if="index === assigning">
          <div class="flex items-center">
            <a-loader class="mr-2" /> Asignando...
          </div>
        </template>
        <template v-else>
          {{ loadingTeachers ? lang.section.loading_teachers : getTeacher(item) }}
        </template>
      </template>
      <template #td(actions)="{ item, index }">
        <div class="flex items-center">
          <a-dropdown v-if="!user.isCoordinator" search>
            <button :disabled="assigning === index" class="mr-2 px-4 py-2 rounded-lg focus:outline-none" @click="openActions">
              {{ item.pivot.teacher_id ? 'Cambiar profesor' : 'Asignar profesor' }}
            </button>
            <template #menu class="max-h-16 overflow-y-scroll">
              <a-input type="search" class="px-2" v-model="search"/>
              <a-dropdown-item class="w-full" v-for="teacher in teach" :key="teacher.id" @click="assignTeacher(item, teacher, index)">
                {{ `${teacher.first_name} ${teacher.last_name}` }}
              </a-dropdown-item>
            </template>
          </a-dropdown>
          <a-dropdown>
            <template #menu>
              <a-dropdown-item :to="{ name: 'section_course_set_schedule', params: { section_id: section.id, course_id: item.id } }" v-if="user.isAdmin">
                {{ lang.section.table.actions.buttons.set_schedule }}
              </a-dropdown-item>
              <a-dropdown-item :to="{ name: 'section_course_create_final_delivery', params: { section_id: section.id, course_id: item.id }}" v-if="hasTeacher(item) && user.isAdmin">
                {{ lang.section.table.actions.buttons.create_final_delivery }}
              </a-dropdown-item>
<!--              <a-droppdown-item>-->
<!--                <p class="p-2">Sin acciones</p>-->
<!--              </a-droppdown-item>-->
            </template>
          </a-dropdown>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState({
      section: state => state.sections.section,
      teachers: state => state.teachers.all.filter($0 => $0.enabled),
      loadingTeachers: state => state.teachers.loading,
      loading: ({ sections }) => sections.loading,
      user: state => state.session.user
    }),
    ...mapGetters({
      lang: 'lang',
      columns: 'sections/detailColumns',
      columnsCoord: 'sections/detailColumnsForCoord'
    }),
  },
  data: () => ({
    assigning: null,
    search: '',
    teach: []
  }),
  watch: {
    search(e) {
      this.teach = this.teachers.filter($0 => $0.first_name.toLowerCase().includes(e.toLowerCase()) || $0.last_name.toLowerCase().includes(e.toLowerCase()))
    }
  },
  methods: {
    openActions() {
      this.teach = this.teachers
    },
    assignTeacher(item, teacher) {
      // this.assigning = index
      this.assign({ ...this.$route.params, teacher_id: teacher.id, course_id: item.id })
        .then(() => {
          const { section_id } = this.$route.params
          this.fetchSection(section_id)
          // .finally(() => this.assigning = null)
        })

    },
    ...mapActions({
      toggleActive: 'sections/toggleActive',
      fetchTeachers: 'teachers/index',
      assign: 'sections/assignTeacherToSectionCourse',
      fetchSection: 'sections/fetch',
    }),
    getTeacher(item) {
      const found = this.teachers.find(el => item.pivot.teacher_id === el.id)
      if (found) {
        return `${found.first_name} ${found.last_name}`
      }
      return this.lang.section.no_teacher_assigned
    },
    hasTeacher(item) {
      return this.teachers.find(el => item.pivot.teacher_id === el.id)
    },
  },
}
</script>
